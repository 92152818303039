.admin-header {
    background-color: #282828;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.admin-header .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.admin-header .rightSide {
    display: flex;
    align-items: center;
}

.admin-header .rightSide > * {
    margin-left: 20px;
}

.admin-header .input-group {
    background-color: #343434;
    border-radius: 5px;
}

.admin-header .input-group-text {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 16px;
}

.admin-header .input-search {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 16px;
}

.admin-header .input-search::placeholder {
    color: #fff;
    font-size: 16px;
}

.rightSide img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
}
