.sider-container{
    padding-bottom: 100px;
}

.mmc-sider{
    background: #282828 !important;
    color: #fff !important;
    border: none !important;
}

.ant-menu {
    margin-top: 50px !important;
}

.ant-menu-item {
    padding: 15px 40px !important;
    display: flex !important;
    align-items: flex-start !important;
    white-space: pre-line !important; 
    height: auto !important;
    line-height: normal !important;
    font-size: 16px !important;
}

.ant-layout-sider-zero-width-trigger {
    font-size: 24px !important;
    padding: 15px !important;
    background-color: var(--main-primary-dark-color) !important;
    white-space: pre-line !important;
    height: auto !important;
    width: auto !important;
    right: -46px !important;
}

.ant-layout-sider-zero-width-trigger:hover {
    color: var(--main-primary-color);
}

.ant-layout-sider-trigger {
    background-color: rgba(235, 235, 235, 0.22) !important;
    border-right: var(--main-primary-dark-color) 1px solid !important;
}

.ant-menu-item-icon {
    font-size: 10px !important;
}

.ant-menu-title-content {
    margin-left: 20px !important;
}


.ant-menu-item-active {
    color: var(--main-primary-color) !important;
}

.ant-menu-item svg.ant-menu-item-icon > path {
    fill: white !important;
}

.ant-menu-item-active svg.ant-menu-item-icon > path {
    fill: var(--main-primary-color) !important;
}

.ant-menu-item-selected svg.ant-menu-item-icon > path {
    fill: var(--main-primary-color) !important;
}

.ant-menu-item-selected {
    color: var(--main-primary-color) !important;
    background-color: rgba(235, 235, 235, 0.22) !important;
    border-right: 2px solid var(--main-primary-color) !important;
}
