.mentorItem.card{
    background: #434245;
    border-radius: 5.4px !important;
    margin-bottom: 3rem;
}
.mentorItem .mentor-details{
    margin-left: -10px;
}
.mentorItem .card-title {
    font-weight: 600;
    font-size: 1.5rem;
}
.mentorItem .card-subtitle {
    font-size: 1rem;
    font-weight: normal;
}
.mentorItem .social-media-icons{
    margin: 10px auto 0;
}
.mentorItem span.username{
    color: rgba(255, 255, 255, 0.9);
    font-weight: 300;
}
.mentorItem .social-media-icons svg{
    padding: 2px;
    width: 17px;
    height: 17px;
}
.mentorItem .social-media-icons svg:nth-child(2){
    fill: #EF951B !important;
}
.mentorItem .social-media-icons span{
    font-size: 1.2rem;
}
.mentorItem .edit-delete{
    display: flex;
    padding-top: 5px;
}
.mentorItem .edit-delete div{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 0.3rem;
    background-color: #666666;
    text-align: center;
}
.mentorItem .edit-delete div:nth-child(2){
    margin-left: 1.5rem;
}
.mentorItem .edit-delete svg{
    width: 15px;
    height: 15px;
}

@media only screen and (min-width: 768px){
    .mentorItem .card-subtitle{
        font-size: 1.4rem;
    }
    .mentorItem .social-media-icons{
        margin: 17px auto 0;
    }
    .mentorItem .mentor-details{
        padding: 20px 15px 0 !important;
    }
    .mentorItem .edit-delete{
        padding: 50px 0 0;
    }
    .mentorItem .card-title {
        font-size: 2.2rem;
    }
}

@media only screen and (min-width: 992px){
    .mentorItem .social-media-icons{
        margin: 20px auto 0;
    }
    .mentorItem .mentor-details{
        padding: 25px 27px 0 !important;
    }
    .mentorItem .edit-delete{
        padding: 60px 20px 0;
    }
    .mentorItem .card-title {
        font-size: 2.2rem;
    }
}

@media only screen and (min-width: 1200px){
    .mentorItem .mentor-details{
        padding: 5px 27px 0 !important;
    }
    .mentorItem .edit-delete{
        padding: 45px 0 0 60px;
    }
    .mentorItem .card-title {
        font-size: 2rem;
    }
}

