.modal-mentor-list {
    min-width: 60% !important;
    max-width: 70% !important;
}

.modal-mentor-list .filter-button > button {
    background: transparent;
    border: 1.07127px solid whitesmoke;
    border-radius: 25px;
    padding: 5px 15px;
    margin-right: 10px;
    font-size: 10px;
}

.modal-mentor-list .modal-header {
    border-bottom: none !important;
}

.modal-mentor-list .modal-content{
    width: 95% !important;
    margin: 0 10px;
    padding: 15px;
    text-align: justify;
    border-radius: 7.61px !important;
    background: #343434 !important;
    color: #fff !important;
    position: relative;
    top: 230px !important;
    right: -15% !important;
}

.modal-mentor-list h5.modal-title{
    color: #fff !important;
    width: 100%;
}
.modal-mentor-list .btn-close{
    background: transparent url('../../svg/Btn-close.svg') center/1em auto no-repeat;
    color: #fff;
}

.modal-mentor-list .card {
    background: #434245 !important;
    border-radius: 1rem !important;
    padding: 5px;
    width: 190px !important;
    margin-bottom: 10px;
}

.modal-mentor-list .card-footer .ant-btn-sm {
    font-size: 10px;
    padding: 0 7px;
}

.modal-mentor-list .card-footer .ant-btn-sm > span {
    margin: 0 !important;
}

.modal-mentor-list .card-footer {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}

.modal-mentor-list .card-body {
    padding: 10px 0;
}

.modal-mentor-list .cycle-select {
    width: 50%;
    margin-bottom: 20px;
}
