/****custom css****/
.adminLogin {
    background-color: #434245;
    padding: 1.25rem !important;
    width: 300px;
    text-align: center;
    border-radius: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
}
.adminLogin h3.ant-typography{
    color: #fff !important;
    line-height: 36px !important;
}
.adminLogin .ant-form-item{
    margin-bottom: 0.9rem !important;
}
.adminLogin .ant-form-item-label > label{
    color: #fff !important;                         
}
.adminLogin .ant-form-item-label > label .ant-form-item-optional {
    display: none !important;
}
.adminLogin .ant-form-item.password{
    position: relative;
}
.adminLogin .ant-form-item.password .ant-form-item-control-input-content a{
    position: absolute;
    bottom: 55px;
    right: 0;
    font-size: 1.4rem;
    color: var(--main-primary-color) !important;
}
.adminLogin .ant-input, .adminLogin .ant-input-affix-wrapper{
    border-radius: 8px !important;
}
.adminLogin .ant-input-password-icon{
    color: #323232 !important;
}
.adminLogin .ant-input:focus{
    border-color: transparent !important;
}
.adminLogin .ant-btn{
    text-transform: uppercase;
    border-radius: 40px !important;
    width: 196px;
    font-weight: 500 !important;
}
.adminLogin .ant-btn.login{
    background: var(--main-primary-color) !important;
    border-color: var(--main-primary-color) !important;
    color: #000 !important;
}


@media (min-width: 768px) {
    .adminLogin{
        width: 591px;
        padding: 1.875rem !important;
    }
    .adminLogin .ant-row.ant-form-item{
        flex-direction: column;
    }
    .adminLogin .ant-form-item-label{
        text-align: left !important;
    }
    .adminLogin .ant-btn{
        width: 296px;
    }
    .adminLogin .ant-form-item.password .ant-form-item-control-input-content a{
        position: absolute;
        bottom: 64px;
        right: 0;
    }
    .adminLogin .ant-input, .adminLogin .ant-btn.login{
        height: 48px;
    }
    .adminLogin .ant-input#Password{
        height: 40px;
    }
}