.createUser .modal-content{
    width: 95% !important;
    margin: 0 10px;
    padding: 15px;
    text-align: justify;
    border-radius: 7.61px !important;
    background: #343434 !important;
    color: #fff !important;
    position: relative;
    top: 230px !important;
}
div .modal + .modal-backdrop{
    background-color: #E5E5E5 !important;
}
.createUser .modal-header{
    border-bottom-width: 0 !important;
}
.createUser .modal-header .ant-form-item-control-input-content{
    width: 100px;
    height: 100px;
    background-color: #fff;
    border-radius: 50%;
    background: url('../../assets/images/avatar.png');
    background-position: center;
    background-size: cover;
    position: relative;
}
.createUser .modal-header .ant-upload-select-picture button.ant-btn-icon-only{
    position: absolute;
    left: 73.94px;
    top: 60px;
    border-radius: 50%;
}
.createUser .btn-close{
    background: transparent url('../../svg/Btn-close.svg') center/1em auto no-repeat;
    color: #fff;
    margin: -5rem -0.5rem -0.5rem auto !important;
}

.createUser  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}
  
.createUser .ant-form-item-label > label{
    color: #fff;
}
.createUser button#createUser{  
    background: var(--main-primary-color);
    border-color: var(--main-primary-color);
}
.createUser button#createUser span{
    color: black;
    font-weight: 600;
}
.createUser .ant-input, .createUser .ant-input-number,
.createUser .ant-select-selector{
    border-radius: 8px !important;
}
.createUser .ant-input:not(textarea.ant-input), .createUser .ant-input-number-input{
    height: 38px;
}
.createUser .ant-select-selector{
    height: 38px !important;
}

@media only screen and (min-width: 768px){
    .createUser .modal-content {
        width: 550px !important;
        margin: 0 10px;
        transform: translate(-40px, 0);
        position: relative;
        top: 180px !important;
    }
}

