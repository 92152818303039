.cycle-card {
    padding: 5px;
}

.cycle-card.inactive {
    border-top: 10px solid #666;
}

.cycle-card .card {
    border: none;
    border-top: 10px solid #1E9B32;
    background: #434245;
    padding: 20px;
    border-radius: 5px;
}

.cycle-card .card-header {
    background: transparent;
    border: none;
    display: flex;
    justify-content: space-between;
}

.cycle-card .card-body {
    display: flex;
    flex-direction: column;
}

.cycle-card .card-subtitle {
    color: #979797;
    font-size: small;
}

.cycle-card .card .section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cycle-card .card .value {
    font-size: 30px;
    margin: 0;
}

.cycle-card .card .ant-progress-bg {
    height: 3px !important;
}

.cycle-card .card .ant-progress-bg::after {
    content: '20%';
    position: absolute;
    color: white;
    top: -15px;
    right: -10px;
}

.cycle-card svg {
    background-color: #666;
    fill: #979797;
    padding: 5px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.cycle-card .cycle-title {
    height: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
