@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.ant-layout-header {
    display: flex;
    justify-content: flex-end;
    height: 80px !important;
    align-items: center;
    background-color: #282828 !important
}  

.ant-layout {
    max-height: 100vh;
}

.ant-layout-sider {
    background-color: #282828 !important;
    overflow: scroll;
}

.ant-layout-content {
    background-color: #343434 !important;
    overflow: scroll;
}

.ant-layout-sider-trigger {
    min-width: 100px !important;
}

.ant-layout-sider:not(.ant-layout-sider-collapsed){
    flex: 0 0 240px !important;
    max-width: 240px !important;
    min-width: 240px !important;
    width: 240px !important;
}

.ant-layout-sider:not(.ant-layout-sider-collapsed) .ant-layout-sider-trigger{
    width: 240px !important;
}

.ant-layout .icon-menu-fold {
    font-size: 24px;
}

.menu-drawer .ant-drawer-body {
    padding: 0;
}

.ant-drawer .ant-drawer-content, .ant-drawer-header.ant-drawer-header-close-only {
    background: #282828;
}

.menu-drawer .ant-drawer-header-title {
    width: 100%;
    justify-content: flex-end;
}

.menu-drawer .anticon.anticon-close {
    color: var(--main-primary-color);
}

.admin-header {
    background-color: #282828;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.admin-header .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.admin-header .rightSide {
    display: flex;
    align-items: center;
}

.admin-header .rightSide > * {
    margin-left: 20px;
}

.admin-header .input-group {
    background-color: #343434;
    border-radius: 5px;
}

.admin-header .input-group-text {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 16px;
}

.admin-header .input-search {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 16px;
}

.admin-header .input-search::-webkit-input-placeholder {
    color: #fff;
    font-size: 16px;
}

.admin-header .input-search:-ms-input-placeholder {
    color: #fff;
    font-size: 16px;
}

.admin-header .input-search::placeholder {
    color: #fff;
    font-size: 16px;
}

.rightSide img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.sider-container{
    padding-bottom: 100px;
}

.mmc-sider{
    background: #282828 !important;
    color: #fff !important;
    border: none !important;
}

.ant-menu {
    margin-top: 50px !important;
}

.ant-menu-item {
    padding: 15px 40px !important;
    display: flex !important;
    align-items: flex-start !important;
    white-space: pre-line !important; 
    height: auto !important;
    line-height: normal !important;
    font-size: 16px !important;
}

.ant-layout-sider-zero-width-trigger {
    font-size: 24px !important;
    padding: 15px !important;
    background-color: var(--main-primary-dark-color) !important;
    white-space: pre-line !important;
    height: auto !important;
    width: auto !important;
    right: -46px !important;
}

.ant-layout-sider-zero-width-trigger:hover {
    color: var(--main-primary-color);
}

.ant-layout-sider-trigger {
    background-color: rgba(235, 235, 235, 0.22) !important;
    border-right: var(--main-primary-dark-color) 1px solid !important;
}

.ant-menu-item-icon {
    font-size: 10px !important;
}

.ant-menu-title-content {
    margin-left: 20px !important;
}


.ant-menu-item-active {
    color: var(--main-primary-color) !important;
}

.ant-menu-item svg.ant-menu-item-icon > path {
    fill: white !important;
}

.ant-menu-item-active svg.ant-menu-item-icon > path {
    fill: var(--main-primary-color) !important;
}

.ant-menu-item-selected svg.ant-menu-item-icon > path {
    fill: var(--main-primary-color) !important;
}

.ant-menu-item-selected {
    color: var(--main-primary-color) !important;
    background-color: rgba(235, 235, 235, 0.22) !important;
    border-right: 2px solid var(--main-primary-color) !important;
}

.adminItemPage *{
    color: #fff;
    margin: 0;
}
.adminItemPage{
    padding: 40px 45px;
}
.adminItemPage .adminHeader{
    position: relative;
}
.adminItemPage #admin{
    position: absolute;
    left: 0;
    font-size: 2rem;
    font-weight: 600;
}
.adminItemPage button#addAdmin{  
    position: absolute;
    right: 0;
    width: 126px;
    padding: 18px;
    font-weight: 600;
    
    background: var(--main-primary-color);
    border-color: var(--main-primary-color);
}
.adminItemPage button#addAdmin span{
    color: black;
    font-size: 0.93rem;
}
.adminItemPage button#addAdmin svg{
    font-size: 0.6rem;
}
.adminItemPage .card-modal-list{
    position: relative;
    top: 70px;
}
.adminItemPage .paginationComponent{
    position: relative;
    top: 25px;
    margin: 70px 0 0;
    padding-bottom: 20px;
}

@media only screen and (min-width: 768px){
    .adminItemPage{
        padding: 45px 50px;
    }
    .adminItemPage .paginationComponent{
        position: relative;
        top: 35px;
        margin: 100px 0;
    }
}

@media only screen and (min-width: 992px){
    .adminItemPage{
        padding: 20px;
    }
}

@media only screen and (min-width: 1200px){
    .adminItemPage{
        padding: 30px;
    }
}


.createAdminForm .modal-content{
    width: 95% !important;
    margin: 0 10px;
    padding: 15px;
    text-align: justify;
    border-radius: 7.61px !important;
    background: #343434 !important;
    color: #fff !important;
    position: relative;
    top: 230px !important;
}
div .modal + .modal-backdrop{
    background-color: #E5E5E5 !important;
}
.createAdminForm .modal-header{
    border-bottom-width: 0 !important;
}
.createAdminForm .modal-header .ant-form-item-control-input-content{
    width: 100px;
    height: 100px;
    background-color: #fff;
    border-radius: 50%;
    background: url(/static/media/avatar.d011667d.png);
    background-position: center;
    background-size: cover;
    position: relative;
}
.createAdminForm .modal-header .ant-upload-select-picture button.ant-btn-icon-only{
    position: absolute;
    left: 73.94px;
    top: 60px;
    border-radius: 50%;
}
.createAdminForm .btn-close{
    background: #ff4d4f url(/static/media/Btn-close.e4ab8e48.svg) center/1em auto no-repeat;
    margin: -5rem -0.5rem -0.5rem auto !important;
}
.createAdminForm  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}
.createAdminForm .ant-form-item-label > label{
    color: #fff;
}
.createAdminForm button#createAdmin{  
    background: var(--main-primary-color);
    border-color: var(--main-primary-color);
}
.createAdminForm button#createAdmin span{
    color: black;
    font-weight: 600;
}
.createAdminForm .ant-input, .createAdminForm .ant-input-number,
.createAdminForm .ant-select-selector{
    border-radius: 8px !important;
}
.createAdminForm h4.account-info{
    font-weight: 600;
}


@media only screen and (min-width: 768px){
    .createAdminForm .modal-content {
        width: 550px !important;
        margin: 0 10px;
        -webkit-transform: translate(-40px, 0);
                transform: translate(-40px, 0);
        position: relative;
        top: 180px !important;
    }
}


.createUser .modal-content{
    width: 95% !important;
    margin: 0 10px;
    padding: 15px;
    text-align: justify;
    border-radius: 7.61px !important;
    background: #343434 !important;
    color: #fff !important;
    position: relative;
    top: 230px !important;
}
div .modal + .modal-backdrop{
    background-color: #E5E5E5 !important;
}
.createUser .modal-header{
    border-bottom-width: 0 !important;
}
.createUser .modal-header .ant-form-item-control-input-content{
    width: 100px;
    height: 100px;
    background-color: #fff;
    border-radius: 50%;
    background: url(/static/media/avatar.d011667d.png);
    background-position: center;
    background-size: cover;
    position: relative;
}
.createUser .modal-header .ant-upload-select-picture button.ant-btn-icon-only{
    position: absolute;
    left: 73.94px;
    top: 60px;
    border-radius: 50%;
}
.createUser .btn-close{
    background: transparent url(/static/media/Btn-close.e4ab8e48.svg) center/1em auto no-repeat;
    color: #fff;
    margin: -5rem -0.5rem -0.5rem auto !important;
}

.createUser  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}
  
.createUser .ant-form-item-label > label{
    color: #fff;
}
.createUser button#createUser{  
    background: var(--main-primary-color);
    border-color: var(--main-primary-color);
}
.createUser button#createUser span{
    color: black;
    font-weight: 600;
}
.createUser .ant-input, .createUser .ant-input-number,
.createUser .ant-select-selector{
    border-radius: 8px !important;
}
.createUser .ant-input:not(textarea.ant-input), .createUser .ant-input-number-input{
    height: 38px;
}
.createUser .ant-select-selector{
    height: 38px !important;
}

@media only screen and (min-width: 768px){
    .createUser .modal-content {
        width: 550px !important;
        margin: 0 10px;
        -webkit-transform: translate(-40px, 0);
                transform: translate(-40px, 0);
        position: relative;
        top: 180px !important;
    }
}


.adminItem.card{
    background: #434245;
    border-radius: 5.4px !important;
    margin-bottom: 3rem;
}
.adminItem .admin-details{
    margin-left: -10px;
}
.adminItem .card-title {
    font-weight: 600;
    font-size: 1.5rem;
}
.adminItem .card-subtitle {
    font-size: 1rem;
    font-weight: normal;
}
.adminItem .social-media-icons{
    margin: 10px auto 0;
}
.adminItem span.username{
    color: rgba(255, 255, 255, 0.9);
    font-weight: 300;
}
.adminItem .social-media-icons svg{
    padding: 2px;
    width: 17px;
    height: 17px;
}
.adminItem .social-media-icons svg:nth-child(2){
    fill: #EF951B !important;
}
.adminItem .social-media-icons span{
    font-size: 1.2rem;
}
.adminItem .edit-delete{
    display: flex;
    padding-top: 5px;
}
.adminItem .edit-delete button{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 0.3rem;
    background-color: #666666;
    text-align: center;
}
.adminItem .edit-delete button:nth-child(2){
    margin-left: 1.5rem;
}
.adminItem .edit-delete svg{
    width: 15px;
    height: 15px;
}

.ant-popover-content{
    max-width: 90% !important;
    margin: 0 auto !important;
}

.ant-popover-buttons .ant-btn-primary{
    display: inline-block !important;
}

.ant-popover-buttons .ant-btn-primary > span{
    margin-left: 0 !important;
}

@media only screen and (min-width: 768px){
    .adminItem .card-subtitle{
        font-size: 1.4rem;
    }
    .adminItem .social-media-icons{
        margin: 17px auto 0;
    }
    .adminItem .admin-details{
        padding: 20px 15px 0 !important;
    }
    .adminItem .edit-delete{
        padding: 50px 0 0;
    }
    .adminItem .card-title {
        font-size: 2.2rem;
    }
}

@media only screen and (min-width: 992px){
    .adminItem .social-media-icons{
        margin: 20px auto 0;
    }
    .adminItem .admin-details{
        padding: 25px 27px 0 !important;
    }
    .adminItem .edit-delete{
        padding: 60px 20px 0;
    }
    .adminItem .card-title {
        font-size: 2.2rem;
    }
}

@media only screen and (min-width: 1200px){
    .adminItem .admin-details{
        padding: 5px 27px 0 !important;
    }
    .adminItem .edit-delete{
        padding: 45px 0 0 60px;
    }
    .adminItem .card-title {
        font-size: 2rem;
    }
}


.mentorItem.card{
    background: #434245;
    border-radius: 5.4px !important;
    margin-bottom: 3rem;
}
.mentorItem .mentor-details{
    margin-left: -10px;
}
.mentorItem .card-title {
    font-weight: 600;
    font-size: 1.5rem;
}
.mentorItem .card-subtitle {
    font-size: 1rem;
    font-weight: normal;
}
.mentorItem .social-media-icons{
    margin: 10px auto 0;
}
.mentorItem span.username{
    color: rgba(255, 255, 255, 0.9);
    font-weight: 300;
}
.mentorItem .social-media-icons svg{
    padding: 2px;
    width: 17px;
    height: 17px;
}
.mentorItem .social-media-icons svg:nth-child(2){
    fill: #EF951B !important;
}
.mentorItem .social-media-icons span{
    font-size: 1.2rem;
}
.mentorItem .edit-delete{
    display: flex;
    padding-top: 5px;
}
.mentorItem .edit-delete div{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 0.3rem;
    background-color: #666666;
    text-align: center;
}
.mentorItem .edit-delete div:nth-child(2){
    margin-left: 1.5rem;
}
.mentorItem .edit-delete svg{
    width: 15px;
    height: 15px;
}

@media only screen and (min-width: 768px){
    .mentorItem .card-subtitle{
        font-size: 1.4rem;
    }
    .mentorItem .social-media-icons{
        margin: 17px auto 0;
    }
    .mentorItem .mentor-details{
        padding: 20px 15px 0 !important;
    }
    .mentorItem .edit-delete{
        padding: 50px 0 0;
    }
    .mentorItem .card-title {
        font-size: 2.2rem;
    }
}

@media only screen and (min-width: 992px){
    .mentorItem .social-media-icons{
        margin: 20px auto 0;
    }
    .mentorItem .mentor-details{
        padding: 25px 27px 0 !important;
    }
    .mentorItem .edit-delete{
        padding: 60px 20px 0;
    }
    .mentorItem .card-title {
        font-size: 2.2rem;
    }
}

@media only screen and (min-width: 1200px){
    .mentorItem .mentor-details{
        padding: 5px 27px 0 !important;
    }
    .mentorItem .edit-delete{
        padding: 45px 0 0 60px;
    }
    .mentorItem .card-title {
        font-size: 2rem;
    }
}


.mentorsProfile .modal-content{
    width: 95% !important;
    margin: 0 10px;
    padding: 15px;
    text-align: justify;
    border-radius: 7.61px !important;
    background: #343434 !important;
    color: #fff !important;
    position: relative;
    top: 230px !important;
}
div .modal + .modal-backdrop{
    background-color: #E5E5E5 !important;
}
.mentorsProfile .modal-header{
    border-bottom-width: 0 !important;
}
.mentorsProfile h5.modal-title{
    color: #fff !important;
    font-weight: 700;
    font-size: 2rem;
}
.mentorsProfile .btn-close{
    background: transparent url(/static/media/Btn-close.e4ab8e48.svg) center/1em auto no-repeat;
    color: #fff;
}
.mentorsProfile .card{
    background: #434245 !important;
    border-radius: 1rem !important;
}
.mentorsProfile .modal-body .card-img{
    border-radius: 0;
}
.mentorsProfile .card-body{
    padding: 1rem 0 !important;
}
.mentorsProfile .modal-body .container div.social-media-icons{
    margin: 10px auto 0;
}
.mentorsProfile .modal-body .container div.social-media-icons svg{
    padding: 2px;
    width: 17px;
    height: 17px;
}
.mentorsProfile .mentor-full-details{
    margin: 30px auto;
}
.mentorsProfile .details-column:not(.career-brief){
    border-bottom: 0.316434px solid rgba(255, 255, 255, 0.16);
    margin-bottom: 15px;
}
.mentorsProfile p{
    margin-bottom: 0.7rem;
}
.mentorsProfile p.label{
    font-size: 1.1rem;
    color: #979797;
}

@media only screen and (min-width: 768px){
    .mentorsProfile .modal-content{
        position: relative;
        top: 140px !important;
        width: 600px !important;
        margin: 0 -20px;
        -webkit-transform: translate(-30px, 0);
                transform: translate(-30px, 0);
    }
    .mentorsProfile .mentor-full-details{
        margin: 0 auto;
        padding: 0 20px;
    }
    .mentorsProfile .gender{
        margin-left: 2rem;
    }
}

@media only screen and (min-width: 992px){
    .mentorsProfile .modal-content{
        position: relative;
        top: 160px !important;
        width: 600px !important;
        margin: 0 40px;
        -webkit-transform: translate(-10px, 0);
                transform: translate(-10px, 0);
    }
    .mentorsProfile .mentor-full-details{
        margin: 0 auto;
        padding: 0 20px;
    }
}

@media only screen and (min-width: 1200px){
    .mentorsProfile .modal-content{
        position: relative;
        top: 160px !important;
        margin: 20px 30px;
        -webkit-transform: translate(20px, 10px);
                transform: translate(20px, 10px);
    }
    .mentorsProfile .mentor-full-details{
        padding: 0 30px;
    }
    .mentorsProfile p.value{
        font-size: 1.2rem;
    }
}


.pagination {
    margin: 0;
    padding: 0;
    margin-top: 3rem;
}

.pagination .innerElement {
    display: inline;
}

.pagination .innerElement.current-page {
    background-color: var(--main-primary-color) !important;
    display: inline-block;
    width: 100%;
    height: 100%;
}

.pagination .ant-pagination-prev .ant-pagination-item-link, .pagination .ant-pagination-next .ant-pagination-item-link {
    border-radius: 50%;
    background-color: #434245;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

.pagination .ant-pagination-next {
    margin-left: 8px;
}

.pagination .ant-pagination-item {
    background-color: #434245;
    color: white;
    margin: 0;
    border: none;
}

.pagination .ant-pagination-item:hover, .pagination .ant-pagination-item-link:hover {
    cursor: pointer;
    color: var(--main-primary-color) !important;
}

.pagination .ant-pagination > .ant-pagination-item:nth-child(2), .pagination .ant-pagination-item:nth-child(2) .innerElement {
    border-bottom-left-radius: 50% !important;
    border-top-left-radius: 50% !important;
}

.pagination .ant-pagination > .ant-pagination-item:nth-last-child(2), .pagination .ant-pagination-item:nth-last-child(2) .innerElement {
    border-bottom-right-radius: 50% !important;
    border-top-right-radius: 50% !important;
}

.cycles-page {
    padding: 50px !important;
    color: white;
}

.cycles-page > h1 {
    color: white;
}

.add-cycle {
    padding: 5px;
}

.add-cycle .card {
    background: #434245;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    cursor: pointer;
    min-height: 260px;
}


.cycle-card {
    padding: 5px;
}

.cycle-card.inactive {
    border-top: 10px solid #666;
}

.cycle-card .card {
    border: none;
    border-top: 10px solid #1E9B32;
    background: #434245;
    padding: 20px;
    border-radius: 5px;
}

.cycle-card .card-header {
    background: transparent;
    border: none;
    display: flex;
    justify-content: space-between;
}

.cycle-card .card-body {
    display: flex;
    flex-direction: column;
}

.cycle-card .card-subtitle {
    color: #979797;
    font-size: small;
}

.cycle-card .card .section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cycle-card .card .value {
    font-size: 30px;
    margin: 0;
}

.cycle-card .card .ant-progress-bg {
    height: 3px !important;
}

.cycle-card .card .ant-progress-bg::after {
    content: '20%';
    position: absolute;
    color: white;
    top: -15px;
    right: -10px;
}

.cycle-card svg {
    background-color: #666;
    fill: #979797;
    padding: 5px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.cycle-card .cycle-title {
    height: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.add-cycle-modal {
    background-color: #282828;
    border-radius: 15px;
    padding: 15px;
}

.add-cycle-modal .modal-content {
    background-color: #282828;
    color: white;
    border: none;
}

.add-cycle-modal .modal-header {
    border: none;
}

.add-cycle-modal .modal-title {
    color: white !important;
    border: none;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.add-cycle-modal .modal-header h3 {
    color: white;
}

.add-cycle-modal .modal-body .ant-form label {
    color: white;
}

.add-cycle-modal .ant-input, .add-cycle-modal .ant-picker {
    border-radius: 7px;
}

.add-cycle-modal .ant-input-number {
    width: 130px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.19);
    border: none;
    border-radius: 7px;
}

.add-cycle-modal .anticon-close {
    color: white;
}

.add-cycle-modal .ant-input-number-input-wrap {
    height: 100%;
}

.add-cycle-modal .ant-input-number input {
    text-align: center;
    height: 100%;
    padding-left: 25px;
    padding-right: 25px;
    margin: 0;
}

.add-cycle-modal .ant-input-number-handler {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background-color: #EF951B;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-cycle-modal .ant-input-number-handler > span {
    color: white;
    width: 100% !important;
    height: 100% !important;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 12px !important;
    left: 0px;
}

.add-cycle-modal .ant-input-number-handler-wrap {
    width: 100%;
    background-color: transparent;
}

.add-cycle-modal .ant-input-number-handler-up {
    right: 5px;
}

.add-cycle-modal .ant-input-number-handler-down {
    left: 5px;
}

.add-cycle-modal .date-range {
    display: flex;
}

.add-cycle-modal .date-range > div {
    width: 50%;
}

.add-cycle-modal .date-range .ant-picker {
    width: 95%;
}

.add-cycle-modal .date-range .ant-picker-suffix {
    color: #282828;
}

.add-cycle-modal .button-container {
    display: flex !important;
    flex-direction: row;
}

.dashboard {
    padding: 20px;
}

.dashboard * {
    color: white;
    margin: 0;
}

.dashboard .row {
    --bs-gutter-x: unset;
}

.dashboard .card {
    height: 210px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #535353;
    border-radius: 5px;
    margin-bottom: 10px;
}

.dashboard .cards {
    justify-content: space-between;
}

.dashboard .cards .card > h1 {
    font-size: 100px;
    color: var(--main-primary-color);
    font-weight: 700;
    padding: 0
}

.dashboard .cards .card > p {
    font-size: 25px;
    font-weight: 200;
    margin-top: -20px;
}

.dashboard .cards .card.right {
    background-color: #434245;
}

.dashboard .cards .card.right > h1 {
    color: white;
    margin-right: 0;
}

.dashboard .admin {
    margin: 60px 0;
    margin-bottom: 80px;
}

.dashboard .admin .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.dashboard .pr {
    padding-right: 20px;
}

.dashboard .pl {
    padding-left: 20px;
}

.dashboard .admin .card {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    min-height: 75px;
    height: unset;
    padding: 0 15px;
    margin-bottom: 10px;
}

.dashboard .admin .card > div:nth-child(1) {
    text-align: left;
    font-weight: 200;
    margin-top: 2rem;
}

.dashboard .admin .card > div:nth-child(2) {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.dashboard .admin .card > div:nth-child(2) > svg, .dashboard .mentees .mentee-card .card-content svg {
    background-color: #666666;
    padding: 7px;
    width: 15px;
    height: 15px;
    box-sizing: content-box;
    border-radius: 50%;
}

.dashboard .admin .card > div:nth-child(2) > svg:hover, .dashboard .mentees .mentee-card .card-content svg:hover  {
    background-color: #888;
    cursor: pointer;
}

.dashboard .mentorship > h3 {
    margin-bottom: 20px;
}

.dashboard .scheme-card {
    background-color: #434245;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
}

.dashboard .scheme-card .scheme {
    display: flex !important;
    align-items: center !important;
    border-right: 0.634817px solid #535353 !important;
}

.dashboard .scheme-card .actions {
    display: flex !important;
    flex: auto !important;
    align-items: center !important;
    justify-content: flex-start !important;
    flex-wrap: wrap !important;
}

.dashboard .scheme-card .actions .ant-btn-secondary {
    background: transparent !important;
    border: 1px solid var(--main-primary-color);
}

.dashboard .scheme-card .ant-select {
    width: 90% !important;
}

.dashboard .scheme-card .actions .ant-btn-secondary:hover {
    background-color: #888 !important;
}

.dashboard .scheme-card .actions .ant-btn-secondary > span {
    color: white !important;
}

.dashboard .scheme-card .actions .ant-btn-primary > span {
    color: black !important;
}

.dashboard .scheme-card .cycle-date {
    font-size: 12px;
    margin-bottom: 6px;
    border-bottom: 1px #535353 solid;
}

.dashboard .activities {
    margin-bottom: 15px;
}


.dashboard .activity {
    padding: 15px !important;
    background-color: #535353;
    margin-bottom: 30px;
    overflow: scroll;
    border-radius: 5px;
}

.dashboard .ant-table, .dashboard .ant-table .ant-table-row > .ant-table-cell-row-hover, .dashboard .ant-table-tbody > tr.ant-table-row-selected > td, .dashboard .ant-table-thead > tr > th {
    background: transparent;
    color: white !important;
}

.dashboard .ant-table-thead > tr > th:nth-child(3) {
    min-width: 200px;
}

.dashboard .ant-table-wrapper .ant-pagination {
    display: none;
}

.dashboard .ant-table-thead > tr > th {
    border: none;
}

.dashboard .ant-table-thead {
    background-color: #434245;
    border: none;
}

.dashboard .activity > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}

.dashboard .mentees > h3 {
    margin-bottom: 20px;
}

.dashboard .mentees .mentee-card {
    background: #434245;
    border-radius: 10px;
    margin-bottom: 10px;
}

.dashboard .mentees .mentee-card .card-subtitle {
    font-size: small;
}

.dashboard .mentees .mentee-card .card-content {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.dashboard .recharts-responsive-container {
    background-color: #535353;
    border-radius: 5px;
}

.dashboard .activities-topdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.dashboard .activities-topdiv .sort {
    background-color: #282828;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: x-small;
}

.dashboard .activities-topdiv .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
}

@media only screen and (min-width: 768px) {
    .dashboard {
        padding: 50px;
    }
    .dashboard .mentees .mentee-card .card-content {
        align-items: center;
        flex-direction: row;
    }

    .dashboard .admin .card > div:nth-child(2) {
        justify-content:flex-end;
    }

    .dashboard .admin .card > div:nth-child(1) {
        margin-top: unset;
    }
    
    .dashboard .admin .card > div:nth-child(2) {
        margin-top: unset;
        margin-bottom: unset;
    }
    .dashboard .scheme-card .actions {
        justify-content: center !important;
    }

}

.modal-mentor-list {
    min-width: 60% !important;
    max-width: 70% !important;
}

.modal-mentor-list .filter-button > button {
    background: transparent;
    border: 1.07127px solid whitesmoke;
    border-radius: 25px;
    padding: 5px 15px;
    margin-right: 10px;
    font-size: 10px;
}

.modal-mentor-list .modal-header {
    border-bottom: none !important;
}

.modal-mentor-list .modal-content{
    width: 95% !important;
    margin: 0 10px;
    padding: 15px;
    text-align: justify;
    border-radius: 7.61px !important;
    background: #343434 !important;
    color: #fff !important;
    position: relative;
    top: 230px !important;
    right: -15% !important;
}

.modal-mentor-list h5.modal-title{
    color: #fff !important;
    width: 100%;
}
.modal-mentor-list .btn-close{
    background: transparent url(/static/media/Btn-close.e4ab8e48.svg) center/1em auto no-repeat;
    color: #fff;
}

.modal-mentor-list .card {
    background: #434245 !important;
    border-radius: 1rem !important;
    padding: 5px;
    width: 190px !important;
    margin-bottom: 10px;
}

.modal-mentor-list .card-footer .ant-btn-sm {
    font-size: 10px;
    padding: 0 7px;
}

.modal-mentor-list .card-footer .ant-btn-sm > span {
    margin: 0 !important;
}

.modal-mentor-list .card-footer {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}

.modal-mentor-list .card-body {
    padding: 10px 0;
}

.modal-mentor-list .cycle-select {
    width: 50%;
    margin-bottom: 20px;
}

/****custom css****/
.adminLogin {
    background-color: #434245;
    padding: 1.25rem !important;
    width: 300px;
    text-align: center;
    border-radius: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: #fff !important;
}
.adminLogin h3.ant-typography{
    color: #fff !important;
    line-height: 36px !important;
}
.adminLogin .ant-form-item{
    margin-bottom: 0.9rem !important;
}
.adminLogin .ant-form-item-label > label{
    color: #fff !important;                         
}
.adminLogin .ant-form-item-label > label .ant-form-item-optional {
    display: none !important;
}
.adminLogin .ant-form-item.password{
    position: relative;
}
.adminLogin .ant-form-item.password .ant-form-item-control-input-content a{
    position: absolute;
    bottom: 55px;
    right: 0;
    font-size: 1.4rem;
    color: var(--main-primary-color) !important;
}
.adminLogin .ant-input, .adminLogin .ant-input-affix-wrapper{
    border-radius: 8px !important;
}
.adminLogin .ant-input-password-icon{
    color: #323232 !important;
}
.adminLogin .ant-input:focus{
    border-color: transparent !important;
}
.adminLogin .ant-btn{
    text-transform: uppercase;
    border-radius: 40px !important;
    width: 196px;
    font-weight: 500 !important;
}
.adminLogin .ant-btn.login{
    background: var(--main-primary-color) !important;
    border-color: var(--main-primary-color) !important;
    color: #000 !important;
}


@media (min-width: 768px) {
    .adminLogin{
        width: 591px;
        padding: 1.875rem !important;
    }
    .adminLogin .ant-row.ant-form-item{
        flex-direction: column;
    }
    .adminLogin .ant-form-item-label{
        text-align: left !important;
    }
    .adminLogin .ant-btn{
        width: 296px;
    }
    .adminLogin .ant-form-item.password .ant-form-item-control-input-content a{
        position: absolute;
        bottom: 64px;
        right: 0;
    }
    .adminLogin .ant-input, .adminLogin .ant-btn.login{
        height: 48px;
    }
    .adminLogin .ant-input#Password{
        height: 40px;
    }
}
.mentees-page {
    padding: 50px !important;
    color: white;
}

.mentees-page > h1 {
    color: white;
}

.mentees-page .mentee-card .card-content svg {
    margin-left: 10px;
    background-color: #666666;
    padding: 7px;
    width: 15px;
    height: 15px;
    box-sizing: content-box;
    border-radius: 50%;
}

.mentees-page .mentee-card .card-content svg:hover  {
    background-color: #888;
    cursor: pointer;
}

.mentees-page .mentee-card {
    background: #434245;
    border-radius: 10px;
    margin-bottom: 10px;
}

.mentees-page .mentee-card .card-subtitle {
    font-size: small;
}

.mentees-page .mentee-card .card-content {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.mentees-page .card-content > div:nth-child(2) {
    display: flex;
    align-items: center;
}

@media only screen and (min-width: 768px) {
    .mentees-page .mentee-card .card-content {
        flex-direction: row;
        align-items: center;
    }
}

.mentorsPage *{
    color: #fff;
    margin: 0;
}
.mentorsPage{
    padding: 40px 45px;
}
.mentorsPage .mentorsHeader{
    position: relative;
}
.mentorsPage #mentors{
    font-size: 2rem;
    font-weight: 600;
}

.mentorsPage .add-buttons {
    display: flex;
}

.mentorsPage button#addMentor{
    padding: 18px;
    font-weight: 600;
    font-size: 1rem;
    background: var(--main-primary-color);
    border-color: var(--main-primary-color);
}
.mentorsPage button#addMentor span{
    color: black;
}
.mentorsPage .card-modal-list{
    position: relative;
    top: 70px;
}
.mentorsPage .paginationComponent{
    position: relative;
    top: 25px;
    margin: 70px 0 0;
    padding-bottom: 20px;
}

@media only screen and (min-width: 768px){
    .mentorsPage{
        padding: 45px 50px;
    }
    .mentorsPage .paginationComponent{
        position: relative;
        top: 35px;
        margin: 100px 0;
    }
    .mentorsPage .add-buttons {
        justify-content: right;
    }
}

@media only screen and (min-width: 992px){
    .mentorsPage{
        padding: 20px;
    }
}

@media only screen and (min-width: 1200px){
    .mentorsPage{
        padding: 30px;
    }
}


.upload-mentor-modal {
  background-color: #282828;
  border-radius: 15px;
  padding: 15px;
  margin-top: 50%;
}

.upload-mentor-modal .modal-content {
  background-color: #282828;
  color: white;
  border: none;
}

.upload-mentor-modal .modal-header {
  border: none;
}

.upload-mentor-modal .modal-title {
  color: white !important;
  border: none;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.upload-mentor-modal .modal-header h3 {
  color: white;
}

@media only screen and (min-width:768px) {
  .upload-mentor-modal {
    margin-top: 10%;
  }
}
:root {
    --main-primary-color: #EF951B;
    --main-danger-color: #FF3D00;
    --main-primary-dark-color: #000000;
    --main-primary-color-hover: #de860acb;
  }
  
  html {
    font-size: 62.5%;
  }
  
  body{
    font-family: 'Poppins', sans-serif !important;
    font-style: normal;
    color: #EF951B;
    color: var(--main-primary-color);
  }
  
  .mmc-text-primary{
    color: #EF951B !important;
    color: var(--main-primary-color) !important;
  }
  
  .mmc-text-primary-dark{
    color: #000000 !important;
    color: var(--main-primary-dark-color) !important;
  }
  
  .mmc-bg-primary{
    background-color: #EF951B !important;
    background-color: var(--main-primary-color) !important;
  }
  
  .mmc-bg-primary-dark{
    background-color: #000000 !important;
    background-color: var(--main-primary-dark-color) !important;
  }
  
  .cursor {
    cursor: pointer;
  }
  
  .responsive-img{
    width: 100%;
    height: auto;
  }
  
  .ant-btn-primary {
    background-color: #EF951B !important;
    background-color: var(--main-primary-color) !important;
    border: none !important;
    display: flex !important;
    align-items: center !important;
  }

  .ant-btn-primary:hover {
    background-color: #de860acb !important;
    background-color: var(--main-primary-color-hover) !important;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: #c30b0d;
    font-size: 14px;
    line-height: 1;
    content: '*';
  }
  
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  
  .ant-form-item-label > label{
    font-size: 1.5rem;
  }
  
  .ant-form-item-explain.ant-form-item-explain-error {
    color: #c30b0d;
  }
  
  .ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input:hover, .ant-form-item-has-error .ant-input-affix-wrapper:hover {
    background-color: #fff;
    border-color: #c30b0d;
  }


