.pagination {
    margin: 0;
    padding: 0;
    margin-top: 3rem;
}

.pagination .innerElement {
    display: inline;
}

.pagination .innerElement.current-page {
    background-color: var(--main-primary-color) !important;
    display: inline-block;
    width: 100%;
    height: 100%;
}

.pagination .ant-pagination-prev .ant-pagination-item-link, .pagination .ant-pagination-next .ant-pagination-item-link {
    border-radius: 50%;
    background-color: #434245;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

.pagination .ant-pagination-next {
    margin-left: 8px;
}

.pagination .ant-pagination-item {
    background-color: #434245;
    color: white;
    margin: 0;
    border: none;
}

.pagination .ant-pagination-item:hover, .pagination .ant-pagination-item-link:hover {
    cursor: pointer;
    color: var(--main-primary-color) !important;
}

.pagination .ant-pagination > .ant-pagination-item:nth-child(2), .pagination .ant-pagination-item:nth-child(2) .innerElement {
    border-bottom-left-radius: 50% !important;
    border-top-left-radius: 50% !important;
}

.pagination .ant-pagination > .ant-pagination-item:nth-last-child(2), .pagination .ant-pagination-item:nth-last-child(2) .innerElement {
    border-bottom-right-radius: 50% !important;
    border-top-right-radius: 50% !important;
}
