:root {
    --main-primary-color: #EF951B;
    --main-danger-color: #FF3D00;
    --main-primary-dark-color: #000000;
    --main-primary-color-hover: #de860acb;
  }
  
  html {
    font-size: 62.5%;
  }
  
  body{
    font-family: 'Poppins', sans-serif !important;
    font-style: normal;
    color: var(--main-primary-color);
  }
  
  .mmc-text-primary{
    color: var(--main-primary-color) !important;
  }
  
  .mmc-text-primary-dark{
    color: var(--main-primary-dark-color) !important;
  }
  
  .mmc-bg-primary{
    background-color: var(--main-primary-color) !important;
  }
  
  .mmc-bg-primary-dark{
    background-color: var(--main-primary-dark-color) !important;
  }
  
  .cursor {
    cursor: pointer;
  }
  
  .responsive-img{
    width: 100%;
    height: auto;
  }
  
  .ant-btn-primary {
    background-color: var(--main-primary-color) !important;
    border: none !important;
    display: flex !important;
    align-items: center !important;
  }

  .ant-btn-primary:hover {
    background-color: var(--main-primary-color-hover) !important;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: #c30b0d;
    font-size: 14px;
    line-height: 1;
    content: '*';
  }
  
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  
  .ant-form-item-label > label{
    font-size: 1.5rem;
  }
  
  .ant-form-item-explain.ant-form-item-explain-error {
    color: #c30b0d;
  }
  
  .ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input:hover, .ant-form-item-has-error .ant-input-affix-wrapper:hover {
    background-color: #fff;
    border-color: #c30b0d;
  }