.cycles-page {
    padding: 50px !important;
    color: white;
}

.cycles-page > h1 {
    color: white;
}

.add-cycle {
    padding: 5px;
}

.add-cycle .card {
    background: #434245;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    cursor: pointer;
    min-height: 260px;
}

