.mentorsProfile .modal-content{
    width: 95% !important;
    margin: 0 10px;
    padding: 15px;
    text-align: justify;
    border-radius: 7.61px !important;
    background: #343434 !important;
    color: #fff !important;
    position: relative;
    top: 230px !important;
}
div .modal + .modal-backdrop{
    background-color: #E5E5E5 !important;
}
.mentorsProfile .modal-header{
    border-bottom-width: 0 !important;
}
.mentorsProfile h5.modal-title{
    color: #fff !important;
    font-weight: 700;
    font-size: 2rem;
}
.mentorsProfile .btn-close{
    background: transparent url('../../svg/Btn-close.svg') center/1em auto no-repeat;
    color: #fff;
}
.mentorsProfile .card{
    background: #434245 !important;
    border-radius: 1rem !important;
}
.mentorsProfile .modal-body .card-img{
    border-radius: 0;
}
.mentorsProfile .card-body{
    padding: 1rem 0 !important;
}
.mentorsProfile .modal-body .container div.social-media-icons{
    margin: 10px auto 0;
}
.mentorsProfile .modal-body .container div.social-media-icons svg{
    padding: 2px;
    width: 17px;
    height: 17px;
}
.mentorsProfile .mentor-full-details{
    margin: 30px auto;
}
.mentorsProfile .details-column:not(.career-brief){
    border-bottom: 0.316434px solid rgba(255, 255, 255, 0.16);
    margin-bottom: 15px;
}
.mentorsProfile p{
    margin-bottom: 0.7rem;
}
.mentorsProfile p.label{
    font-size: 1.1rem;
    color: #979797;
}

@media only screen and (min-width: 768px){
    .mentorsProfile .modal-content{
        position: relative;
        top: 140px !important;
        width: 600px !important;
        margin: 0 -20px;
        transform: translate(-30px, 0);
    }
    .mentorsProfile .mentor-full-details{
        margin: 0 auto;
        padding: 0 20px;
    }
    .mentorsProfile .gender{
        margin-left: 2rem;
    }
}

@media only screen and (min-width: 992px){
    .mentorsProfile .modal-content{
        position: relative;
        top: 160px !important;
        width: 600px !important;
        margin: 0 40px;
        transform: translate(-10px, 0);
    }
    .mentorsProfile .mentor-full-details{
        margin: 0 auto;
        padding: 0 20px;
    }
}

@media only screen and (min-width: 1200px){
    .mentorsProfile .modal-content{
        position: relative;
        top: 160px !important;
        margin: 20px 30px;
        transform: translate(20px, 10px);
    }
    .mentorsProfile .mentor-full-details{
        padding: 0 30px;
    }
    .mentorsProfile p.value{
        font-size: 1.2rem;
    }
}

