.mentees-page {
    padding: 50px !important;
    color: white;
}

.mentees-page > h1 {
    color: white;
}

.mentees-page .mentee-card .card-content svg {
    margin-left: 10px;
    background-color: #666666;
    padding: 7px;
    width: 15px;
    height: 15px;
    box-sizing: content-box;
    border-radius: 50%;
}

.mentees-page .mentee-card .card-content svg:hover  {
    background-color: #888;
    cursor: pointer;
}

.mentees-page .mentee-card {
    background: #434245;
    border-radius: 10px;
    margin-bottom: 10px;
}

.mentees-page .mentee-card .card-subtitle {
    font-size: small;
}

.mentees-page .mentee-card .card-content {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.mentees-page .card-content > div:nth-child(2) {
    display: flex;
    align-items: center;
}

@media only screen and (min-width: 768px) {
    .mentees-page .mentee-card .card-content {
        flex-direction: row;
        align-items: center;
    }
}
