.dashboard {
    padding: 20px;
}

.dashboard * {
    color: white;
    margin: 0;
}

.dashboard .row {
    --bs-gutter-x: unset;
}

.dashboard .card {
    height: 210px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #535353;
    border-radius: 5px;
    margin-bottom: 10px;
}

.dashboard .cards {
    justify-content: space-between;
}

.dashboard .cards .card > h1 {
    font-size: 100px;
    color: var(--main-primary-color);
    font-weight: 700;
    padding: 0
}

.dashboard .cards .card > p {
    font-size: 25px;
    font-weight: 200;
    margin-top: -20px;
}

.dashboard .cards .card.right {
    background-color: #434245;
}

.dashboard .cards .card.right > h1 {
    color: white;
    margin-right: 0;
}

.dashboard .admin {
    margin: 60px 0;
    margin-bottom: 80px;
}

.dashboard .admin .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.dashboard .pr {
    padding-right: 20px;
}

.dashboard .pl {
    padding-left: 20px;
}

.dashboard .admin .card {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    min-height: 75px;
    height: unset;
    padding: 0 15px;
    margin-bottom: 10px;
}

.dashboard .admin .card > div:nth-child(1) {
    text-align: left;
    font-weight: 200;
    margin-top: 2rem;
}

.dashboard .admin .card > div:nth-child(2) {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.dashboard .admin .card > div:nth-child(2) > svg, .dashboard .mentees .mentee-card .card-content svg {
    background-color: #666666;
    padding: 7px;
    width: 15px;
    height: 15px;
    box-sizing: content-box;
    border-radius: 50%;
}

.dashboard .admin .card > div:nth-child(2) > svg:hover, .dashboard .mentees .mentee-card .card-content svg:hover  {
    background-color: #888;
    cursor: pointer;
}

.dashboard .mentorship > h3 {
    margin-bottom: 20px;
}

.dashboard .scheme-card {
    background-color: #434245;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
}

.dashboard .scheme-card .scheme {
    display: flex !important;
    align-items: center !important;
    border-right: 0.634817px solid #535353 !important;
}

.dashboard .scheme-card .actions {
    display: flex !important;
    flex: auto !important;
    align-items: center !important;
    justify-content: flex-start !important;
    flex-wrap: wrap !important;
}

.dashboard .scheme-card .actions .ant-btn-secondary {
    background: transparent !important;
    border: 1px solid var(--main-primary-color);
}

.dashboard .scheme-card .ant-select {
    width: 90% !important;
}

.dashboard .scheme-card .actions .ant-btn-secondary:hover {
    background-color: #888 !important;
}

.dashboard .scheme-card .actions .ant-btn-secondary > span {
    color: white !important;
}

.dashboard .scheme-card .actions .ant-btn-primary > span {
    color: black !important;
}

.dashboard .scheme-card .cycle-date {
    font-size: 12px;
    margin-bottom: 6px;
    border-bottom: 1px #535353 solid;
}

.dashboard .activities {
    margin-bottom: 15px;
}


.dashboard .activity {
    padding: 15px !important;
    background-color: #535353;
    margin-bottom: 30px;
    overflow: scroll;
    border-radius: 5px;
}

.dashboard .ant-table, .dashboard .ant-table .ant-table-row > .ant-table-cell-row-hover, .dashboard .ant-table-tbody > tr.ant-table-row-selected > td, .dashboard .ant-table-thead > tr > th {
    background: transparent;
    color: white !important;
}

.dashboard .ant-table-thead > tr > th:nth-child(3) {
    min-width: 200px;
}

.dashboard .ant-table-wrapper .ant-pagination {
    display: none;
}

.dashboard .ant-table-thead > tr > th {
    border: none;
}

.dashboard .ant-table-thead {
    background-color: #434245;
    border: none;
}

.dashboard .activity > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}

.dashboard .mentees > h3 {
    margin-bottom: 20px;
}

.dashboard .mentees .mentee-card {
    background: #434245;
    border-radius: 10px;
    margin-bottom: 10px;
}

.dashboard .mentees .mentee-card .card-subtitle {
    font-size: small;
}

.dashboard .mentees .mentee-card .card-content {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.dashboard .recharts-responsive-container {
    background-color: #535353;
    border-radius: 5px;
}

.dashboard .activities-topdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.dashboard .activities-topdiv .sort {
    background-color: #282828;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: x-small;
}

.dashboard .activities-topdiv .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
}

@media only screen and (min-width: 768px) {
    .dashboard {
        padding: 50px;
    }
    .dashboard .mentees .mentee-card .card-content {
        align-items: center;
        flex-direction: row;
    }

    .dashboard .admin .card > div:nth-child(2) {
        justify-content:flex-end;
    }

    .dashboard .admin .card > div:nth-child(1) {
        margin-top: unset;
    }
    
    .dashboard .admin .card > div:nth-child(2) {
        margin-top: unset;
        margin-bottom: unset;
    }
    .dashboard .scheme-card .actions {
        justify-content: center !important;
    }

}
