.add-cycle-modal {
    background-color: #282828;
    border-radius: 15px;
    padding: 15px;
}

.add-cycle-modal .modal-content {
    background-color: #282828;
    color: white;
    border: none;
}

.add-cycle-modal .modal-header {
    border: none;
}

.add-cycle-modal .modal-title {
    color: white !important;
    border: none;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.add-cycle-modal .modal-header h3 {
    color: white;
}

.add-cycle-modal .modal-body .ant-form label {
    color: white;
}

.add-cycle-modal .ant-input, .add-cycle-modal .ant-picker {
    border-radius: 7px;
}

.add-cycle-modal .ant-input-number {
    width: 130px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.19);
    border: none;
    border-radius: 7px;
}

.add-cycle-modal .anticon-close {
    color: white;
}

.add-cycle-modal .ant-input-number-input-wrap {
    height: 100%;
}

.add-cycle-modal .ant-input-number input {
    text-align: center;
    height: 100%;
    padding-left: 25px;
    padding-right: 25px;
    margin: 0;
}

.add-cycle-modal .ant-input-number-handler {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    background-color: #EF951B;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-cycle-modal .ant-input-number-handler > span {
    color: white;
    width: 100% !important;
    height: 100% !important;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 12px !important;
    left: 0px;
}

.add-cycle-modal .ant-input-number-handler-wrap {
    width: 100%;
    background-color: transparent;
}

.add-cycle-modal .ant-input-number-handler-up {
    right: 5px;
}

.add-cycle-modal .ant-input-number-handler-down {
    left: 5px;
}

.add-cycle-modal .date-range {
    display: flex;
}

.add-cycle-modal .date-range > div {
    width: 50%;
}

.add-cycle-modal .date-range .ant-picker {
    width: 95%;
}

.add-cycle-modal .date-range .ant-picker-suffix {
    color: #282828;
}

.add-cycle-modal .button-container {
    display: flex !important;
    flex-direction: row;
}
