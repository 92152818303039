.adminItemPage *{
    color: #fff;
    margin: 0;
}
.adminItemPage{
    padding: 40px 45px;
}
.adminItemPage .adminHeader{
    position: relative;
}
.adminItemPage #admin{
    position: absolute;
    left: 0;
    font-size: 2rem;
    font-weight: 600;
}
.adminItemPage button#addAdmin{  
    position: absolute;
    right: 0;
    width: 126px;
    padding: 18px;
    font-weight: 600;
    
    background: var(--main-primary-color);
    border-color: var(--main-primary-color);
}
.adminItemPage button#addAdmin span{
    color: black;
    font-size: 0.93rem;
}
.adminItemPage button#addAdmin svg{
    font-size: 0.6rem;
}
.adminItemPage .card-modal-list{
    position: relative;
    top: 70px;
}
.adminItemPage .paginationComponent{
    position: relative;
    top: 25px;
    margin: 70px 0 0;
    padding-bottom: 20px;
}

@media only screen and (min-width: 768px){
    .adminItemPage{
        padding: 45px 50px;
    }
    .adminItemPage .paginationComponent{
        position: relative;
        top: 35px;
        margin: 100px 0;
    }
}

@media only screen and (min-width: 992px){
    .adminItemPage{
        padding: 20px;
    }
}

@media only screen and (min-width: 1200px){
    .adminItemPage{
        padding: 30px;
    }
}

