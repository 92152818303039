.upload-mentor-modal {
  background-color: #282828;
  border-radius: 15px;
  padding: 15px;
  margin-top: 50%;
}

.upload-mentor-modal .modal-content {
  background-color: #282828;
  color: white;
  border: none;
}

.upload-mentor-modal .modal-header {
  border: none;
}

.upload-mentor-modal .modal-title {
  color: white !important;
  border: none;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.upload-mentor-modal .modal-header h3 {
  color: white;
}

@media only screen and (min-width:768px) {
  .upload-mentor-modal {
    margin-top: 10%;
  }
}