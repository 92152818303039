.mentorsPage *{
    color: #fff;
    margin: 0;
}
.mentorsPage{
    padding: 40px 45px;
}
.mentorsPage .mentorsHeader{
    position: relative;
}
.mentorsPage #mentors{
    font-size: 2rem;
    font-weight: 600;
}

.mentorsPage .add-buttons {
    display: flex;
}

.mentorsPage button#addMentor{
    padding: 18px;
    font-weight: 600;
    font-size: 1rem;
    background: var(--main-primary-color);
    border-color: var(--main-primary-color);
}
.mentorsPage button#addMentor span{
    color: black;
}
.mentorsPage .card-modal-list{
    position: relative;
    top: 70px;
}
.mentorsPage .paginationComponent{
    position: relative;
    top: 25px;
    margin: 70px 0 0;
    padding-bottom: 20px;
}

@media only screen and (min-width: 768px){
    .mentorsPage{
        padding: 45px 50px;
    }
    .mentorsPage .paginationComponent{
        position: relative;
        top: 35px;
        margin: 100px 0;
    }
    .mentorsPage .add-buttons {
        justify-content: right;
    }
}

@media only screen and (min-width: 992px){
    .mentorsPage{
        padding: 20px;
    }
}

@media only screen and (min-width: 1200px){
    .mentorsPage{
        padding: 30px;
    }
}

