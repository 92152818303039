.ant-layout-header {
    display: flex;
    justify-content: flex-end;
    height: 80px !important;
    align-items: center;
    background-color: #282828 !important
}  

.ant-layout {
    max-height: 100vh;
}

.ant-layout-sider {
    background-color: #282828 !important;
    overflow: scroll;
}

.ant-layout-content {
    background-color: #343434 !important;
    overflow: scroll;
}

.ant-layout-sider-trigger {
    min-width: 100px !important;
}

.ant-layout-sider:not(.ant-layout-sider-collapsed){
    flex: 0 0 240px !important;
    max-width: 240px !important;
    min-width: 240px !important;
    width: 240px !important;
}

.ant-layout-sider:not(.ant-layout-sider-collapsed) .ant-layout-sider-trigger{
    width: 240px !important;
}

.ant-layout .icon-menu-fold {
    font-size: 24px;
}

.menu-drawer .ant-drawer-body {
    padding: 0;
}

.ant-drawer .ant-drawer-content, .ant-drawer-header.ant-drawer-header-close-only {
    background: #282828;
}

.menu-drawer .ant-drawer-header-title {
    width: 100%;
    justify-content: flex-end;
}

.menu-drawer .anticon.anticon-close {
    color: var(--main-primary-color);
}
